@import "variables.scss";

.ai6ix {
    &-layout {
        min-height: 100vh;

        &-wrapper {
            width: 100%;
            min-height: 100vh;
            padding-top: 87px;
            display: flex;

            &-full {
                padding-top: 0px;
            }
        }

        &-page {
            width: 100%;
            min-height: 100%;

            &-content {
                width: 100%;
                padding-left: 24px;
                padding-right: 24px;
                padding-bottom: 50px;

                @media (max-width: 767px) {
                    padding-left: 16px;
                    padding-right: 16px;
                }

                &-space {
                    padding-top: 16px;
                    padding-bottom: 16px;

                    &-top {
                        padding-top: 16px;
                    }

                    &-no {
                        padding-top: 0px;
                        padding-bottom: 0px;
                    }
                }
            }

            &-top {
                display: flex;
                justify-content: space-between;
                padding-top: 12px;
                padding-bottom: 24px;
                gap: 10px;
                align-items: center;

                @media (max-width: 767px) {
                    flex-direction: column;
                    width: 100%;
                }

                &-heading {
                    font-size: 24px;
                    font-weight: 900;
                    font-family: var(--hms-ui-fonts-heading);
                    color: var(--hms-ui-colors-white);
                    gap: 10px;
                    line-height: 100%;

                    &-normal {
                        font-weight: 700;
                        font-family: var(--hms-ui-fonts-body);
                    }

                    &-lg {
                        font-size: 34px;

                        @media (max-width: 767px) {
                            font-size: 24px;
                        }
                    }

                    &-line {
                        &::after {
                            content: "";
                            margin-top: 15px;
                            display: block;
                            height: 4px;
                            background-color: var(--hms-ui-colors-yellow);
                        }
                    }
                }
            }

            &-middle {
                max-width: 1100px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &-btn {
        @include vendor-prefix(border-radius, 8px !important);
        font-weight: 600 !important;
        box-shadow: none !important;
        padding: 8px 20px !important;
        white-space: nowrap;

        &-primary {
            border-color: var(--hms-ui-colors-yellow) !important;
            color: var(--hms-ui-colors-white) !important;
            background-color: var(--hms-ui-colors-black) !important;

            &:hover,
            &.active {
                color: var(--hms-ui-colors-yellow) !important;

                svg {
                    path {
                        &[stroke] {
                            stroke: var(--hms-ui-colors-yellow);
                        }

                        &[fill] {
                            fill: var(--hms-ui-colors-yellow);
                        }
                    }
                }
            }
        }

        &-secondary {
            @include vendor-prefix(border-radius, 8px !important);
            border-color: var(--hms-ui-colors-borderLighter) !important;
            color: var(--hms-ui-colors-secondary) !important;

            &:hover,
            &.active {
                background-color: var(--hms-ui-colors-secondary_dim) !important;
                border-color: var(--hms-ui-colors-secondary_dim) !important;
                color: var(--hms-ui-colors-white) !important;
            }
        }

        &-light {
            @include vendor-prefix(border-radius, 8px !important);
            border-color: var(--hms-ui-colors-primaryLightBorder) !important;
            color: var(--hms-ui-colors-light) !important;

            &:hover,
            &.active {
                background-color: var(--hms-ui-colors-primaryLightBorder) !important;
                border-color: var(--hms-ui-colors-primaryLightBorder) !important;
                color: var(--hms-ui-colors-white) !important;
            }
        }

        &-dark {
            @include vendor-prefix(border-radius, 50px !important);
            border-color: var(--hms-ui-colors-dark) !important;
            color: var(--hms-ui-colors-light) !important;
            background-color: var(--hms-ui-colors-dark) !important;

            &:hover {
                border-color: var(--hms-ui-colors-dark-light) !important;
                background-color: var(--hms-ui-colors-dark-light) !important;
                color: var(--hms-ui-colors-white) !important;
            }
        }

        &-danger {
            border-color: var(--hms-ui-colors-danger) !important;
            color: var(--hms-ui-colors-white) !important;
            background-color: var(--hms-ui-colors-black) !important;

            &:hover,
            &.active {
                color: var(--hms-ui-colors-danger) !important;

                svg {
                    path {
                        &[stroke] {
                            stroke: var(--hms-ui-colors-danger);
                        }

                        &[fill] {
                            fill: var(--hms-ui-colors-danger);
                        }
                    }
                }
            }
        }

        &-md {
            padding: 11px 30px !important;
        }

        &-sm {
            padding: 5px 15px !important;
            text-transform: none !important;
            font-weight: normal !important;
        }

        &-round {
            @include vendor-prefix(border-radius, 50px !important);
        }
    }

    &-popup {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9999;
        display: flex;
        align-items: center;
        background: rgba(19, 19, 19, 0.7);
        backdrop-filter: blur(10px);
        overflow: auto;
        padding-top: 40px;
        padding-bottom: 40px;

        &-close {
            position: absolute;
            cursor: pointer;
            right: 0px;
            top: 0;
            padding: 10px 15px;

            &:hover {
                svg path {
                    &[stroke] {
                        stroke: var(--hms-ui-colors-yellow);
                    }

                    &[fill] {
                        fill: var(--hms-ui-colors-yellow);
                    }
                }
            }
        }

        &-inner {
            position: relative;
            color: var(--hms-ui-colors-white);
            gap: 10px;
            width: 1000px;
            background: var(--hms-ui-colors-mainBg);
            border: 1px solid var(--hms-ui-colors-borderDefault);
            border-radius: 6px;
            padding: 24px 24px;
            max-width: 95%;
            margin: auto auto;
            cursor: auto;
        }

        &-sm & {
            &-inner {
                width: 500px;
            }
        }

        &-xs & {
            &-inner {
                width: 400px;
            }
        }

        &-md & {
            &-inner {
                width: 700px;
            }
        }

        &-header {
            padding-bottom: 20px;

            &-heading {
                color: var(--hms-ui-colors-textPrimary);
                font-size: 24px;
                font-weight: 700;
                text-align: center;
            }

            &-heading2 {
                color: var(--hms-ui-colors-textPrimary);
                font-size: 18px;
                font-weight: 700;
                text-align: center;
            }

            &-desc {
                color: #8c8e95;
                font-size: 14px;
                margin-top: 10px;
                text-align: center;
            }
        }

        &-content {
            width: 100%;
        }

        &-footer {
            text-align: center;
            padding-top: 20px;
            margin-top: 20px;
            border-top: 1px solid var(--hms-ui-colors-background_default);
        }
    }

    &-loader {
        color: var(--hms-ui-colors-white);
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;

        &-fixed {
            position: fixed;
            top: 0px;
            bottom: 0px;
            left: 0;
            right: 0;
            z-index: 999999999999999;
            background-color: rgb(33 37 41 / 60%);
        }

        &-dark {
            background-color: var(--hms-ui-colors-black);
            backdrop-filter: blur(3px);
        }
    }

    &-tabs {
        margin: 24px 0;
        color: var(--hms-ui-colors-white);
        overflow: auto;
        white-space: nowrap;
        padding: 0px 0px 5px;

        &-item {
            min-width: 90px;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            color: var(--hms-ui-colors-light);
            @include vendor-prefix(border-radius, 20px);
            padding: 0px 20px;
            line-height: 38px;
            text-transform: capitalize;
            border: 1px solid var(--hms-ui-colors-surface_bright);
            font-size: 16px;

            &:not(:first-child) {
                margin-left: 10px;
            }

            &:hover {
                color: var(--hms-ui-colors-white);
            }

            &.active {
                border: 1px solid var(--hms-ui-colors-yellow);
                color: var(--hms-ui-colors-white);
                background: var(--hms-ui-colors-gray-gradient);
            }
        }
    }

    &-dialog {
        .MuiPaper-root {
            background: var(--hms-ui-colors-mainBg);
            border: 1px solid var(--hms-ui-colors-borderDefault);
            border-radius: 10px;
            color: var(--hms-ui-colors-white);
            padding: 15px;

            @media (max-width: 767px) {
                margin: 0;
                max-width: calc(100vw - 32px);
            }

            h2 {
                font-weight: 700;
                font-size: 22px;
            }

            p {
                color: #979c9e;
            }
        }

        &-action {
            justify-content: center !important;
            gap: 10px;

            button {
                min-width: 120px;
            }
        }
    }
}

.text {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }

    &-underline {
        text-decoration: underline;
    }

    &-capitalize {
        text-transform: capitalize !important;
    }

    &-primary {
        color: var(--hms-ui-colors-yellow);
    }

    &-light {
        color: var(--hms-ui-colors-light);
    }

    &-white {
        color: var(--hms-ui-colors-white);
    }

    &-link {
        cursor: pointer;

        &:hover {
            color: var(--hms-ui-colors-yellow);
        }
    }


}

.custom-form {
    margin: 0;

    &-group {
        width: 100%;
        margin-bottom: 14px !important;

        &.upload-btn {
            width: fit-content;
            margin: 0 !important;
        }

        &.edit-uploadbtn {
            display: flex;
            flex-direction: row;
            margin: 15px 0;
            gap: 15px;
        }

        &-row {
            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 15px;
            }
        }
    }

    &-label {
        font-size: 13px !important;
        color: var(--hms-ui-colors-secondary) !important;
        margin-bottom: 4px;
        display: inline-block;

        &.big-label {
            font-size: 14px !important;
            margin-bottom: 3px !important;
        }
    }

    &-icon {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 20px;
        margin-top: 40px;

        svg {
            width: 14px;
            height: 14px;

            path {
                fill: #10b981;
            }
        }
    }

    &-input {
        background-color: var(--hms-ui-colors-backgroundDefault) !important;
        color: var(--hms-ui-colors-white) !important;
        border: 1px solid var(--hms-ui-colors-borderDefault) !important;
        @include vendor-prefix(border-radius, 8px !important);
        outline: none !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        height: 46px;
        resize: none;
        padding: 0.5rem 0.75rem;

        &[type="password"] {
            &:not(:placeholder-shown) {
                letter-spacing: 0.5px;
                font-size: 200% !important;
                line-height: 20px;
            }
        }

        @include vendor-placeholder {
            color: var(--hms-ui-colors-secondary);
        }

        &[disabled] {
            background-color: var(--hms-ui-colors-surface_bright) !important;

            @include vendor-placeholder {
                color: var(--hms-ui-colors-gray);
            }
        }

        &-inline {
            position: relative;
            align-items: center;

            &-input {
                padding-right: 120px;
            }
        }

        &-surfix {
            position: absolute;
            right: 4px;
            display: flex;
            text-align: center;
            justify-content: center;
            cursor: pointer;
            gap: 7px;
            font-size: 14px;
            color: var(--hms-ui-colors-secondary);
            padding: 7px 7px;
            @include vendor-prefix(border-radius, 6px);
            background-color: var(--hms-ui-colors-surface_bright);

            &-top {
                top: 4px;
            }

            &.disabled {
                cursor: auto;
            }

            &:not(.disabled) {

                &:hover,
                &:focus {
                    color: var(--hms-ui-colors-yellow);
                }
            }
        }

        &-sm {
            font-size: 14px !important;
        }

        &:focus,
        &.active {
            border-color: var(--hms-ui-colors-yellow) !important;
            box-shadow: none;
        }

        &-row {
            position: relative;
        }

        &-additional {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0px;
            padding: 0px 5px;
            display: flex;
            align-items: center;
            gap: 5px;

            button {
                margin: auto;
            }
        }
    }

    &-file {
        display: flex;
        cursor: pointer;
        color: var(--hms-ui-colors-secondary) !important;
        font-size: 16px !important;
        display: flex;
        align-items: center;
        gap: 10px;

        &-input {
            display: none;
        }

        &-preview {
            width: 40px;
            height: 40px;
            border: 1px solid var(--hms-ui-colors-borderDefault);
            background: var(--hms-ui-colors-backgroundDefault);
            @include vendor-prefix(border-radius, 8px);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            &-round {
                @include vendor-prefix(border-radius, 50%);
            }

            img {
                object-fit: cover;
                max-height: 100%;
                max-width: 100%;
                height: auto;
            }
        }

        svg {}
    }

    &-input {
        width: 100%;
    }

    &-checkbox {
        position: relative;

        &-label {
            font-size: 14px !important;
            font-weight: 500 !important;
            color: var(--hms-ui-colors-secondary) !important;
            margin-bottom: 7px;
            display: inline-block;
            padding-left: 30px;
            cursor: pointer;
            position: relative;
            line-height: 20px;

            &::before {
                content: "";
                width: 20px;
                height: 20px;
                background-color: var(--hms-ui-colors-backgroundDefault) !important;
                border: 1px solid var(--hms-ui-colors-borderDefault) !important;
                position: absolute;
                left: 0px;
                @include vendor-prefix(border-radius, 4px);
                text-align: center;
                line-height: 20px;
                overflow: hidden;
            }
        }

        &-normal & {
            &-label {
                padding-left: 0;
                padding-right: 30px;

                &::before {
                    right: 0;
                    left: auto;
                }
            }
        }

        &-input {
            height: 0;
            width: 0;
            visibility: hidden;
            opacity: 0;
            display: none;
        }

        &-input:checked+&-label {
            &::before {
                content: "\2713";
                border-color: var(--hms-ui-colors-yellow) !important;
                color: var(--hms-ui-colors-yellow) !important;
            }
        }
    }

    &-tags {
        .react-tagsinput {
            width: 100%;
            border: none;
            padding: 8px !important;
            @include vendor-prefix(border-radius, 8px);
            min-height: 116px;
            max-height: 150px;
            overflow: auto;
            background-color: var(--hms-ui-colors-backgroundDefault);
            color: var(--hms-ui-colors-white);
            border: 1px solid var(--hms-ui-colors-borderDefault);

            &-tag {
                background: var(--hms-ui-colors-borderDefault);
                border: none;
                margin: 0;
                padding: 6px 8px;
                margin: 5px;
                @include vendor-prefix(border-radius, 6px);
                font-size: 14px;
                color: var(--hms-ui-colors-white);
            }

            &-input {
                width: 200px;
                margin: 0;
                background-color: transparent;
            }
        }
    }

    &-pin {
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-input {
            text-align: center;
            height: 60px;
            font-weight: 600 !important;
        }
    }
}


.mt {
    &-auto {
        margin-top: auto !important;
    }

    &-0 {
        margin-top: 0px !important;
    }

    &-5 {
        margin-top: 5px !important;
    }

    &-10 {
        margin-top: 10px !important;
    }

    &-15 {
        margin-top: 15px !important;
    }

    &-20 {
        margin-top: 20px !important;
    }
}

.mb {
    &-auto {
        margin-bottom: auto !important;
    }

    &-0 {
        margin-bottom: 0px !important;
    }

    &-5 {
        margin-bottom: 5px !important;
    }

    &-10 {
        margin-bottom: 10px !important;
    }

    &-15 {
        margin-bottom: 15px !important;
    }

    &-20 {
        margin-bottom: 20px !important;
    }
}

.ml {
    &-auto {
        margin-left: auto !important;
    }

    &-0 {
        margin-left: 0px !important;
    }

    &-5 {
        margin-left: 5px !important;
    }

    &-10 {
        margin-left: 10px !important;
    }

    &-15 {
        margin-left: 15px !important;
    }

    &-20 {
        margin-left: 20px !important;
    }
}

.mr {
    &-auto {
        margin-right: auto !important;
    }

    &-0 {
        margin-right: 0px !important;
    }

    &-5 {
        margin-right: 5px !important;
    }

    &-10 {
        margin-right: 10px !important;
    }

    &-15 {
        margin-right: 15px !important;
    }

    &-20 {
        margin-right: 20px !important;
    }
}


.Toastify {
    z-index: 99999;
    position: relative;

    &__toast {
        background: var(--hms-ui-colors-surface_bright) !important;
        border: 1px solid var(--hms-ui-colors-surfaceLighter) !important;
        padding: 22px !important;
        border-radius: 16px !important;
        display: flex !important;
        align-items: center;

        button {
            align-self: auto !important;
        }

        &::before {
            position: absolute;
            top: -1px;
            left: calc(8px * -1);
            width: 16px;
            border-left: 1px solid var(--hms-ui-colors-secondary_default);
            border-top: 1px solid var(--hms-ui-colors-secondary_default);
            border-bottom: 1px solid var(--hms-ui-colors-secondary_default);
            @include vendor-prefix(border-top-left-radius, 16px);
            @include vendor-prefix(border-bottom-left-radius, 16px);
            background-color: var(--hms-ui-colors-secondary_default);
            content: " ";
            height: 100%;
            z-index: 10;
        }

        &-body {
            font-size: var(--hms-ui-fontSizes-md);
            color: var(--hms-ui-colors-on_surface_high) !important;
            font-weight: 600;
            font-family: var(--bs-body-font-family);
        }
    }
}

.print {
    &-visible {
        display: none !important;
    }

    &-visible {
        display: none !important;
    }
}